import Api from "@/services/Index";

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/job/logs", config);
};

const getMyLogs = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/job/logs/me", config);
};

const get = async (logId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/job/logs/" + logId);
};

const download = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/job/logs/download/" + id, null, {
        responseType: "arraybuffer",
    });
};

const downloadFailLog = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/job/logs/download-fail-log/" + id, {
        responseType: "arraybuffer",
    });
};

const calculateExtraGraduateCourses = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/extra/graduation/courses/calculate");
};

const autoCourseRegistration = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/course/registration/auto-course-registration", config);
};

const autoRepeatCourseRegistration = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(
        "/course/registration/auto-repeat-course-registration",
        config
    );
};

const autoIrregularCourseRegistration = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/course/registration/auto-irregular-course-registration");
};

const createStudentSemesters = async (semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/semesters/create/" + semesterId);
};

const assignDoesntHavePaymentApproval = async (semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/semesters/assign-doesnt-have-payment-approval/" + semesterId);
};

const assignDoesntHaveCourseRegistration = async (semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/semesters/assign-doesnt-have-course-registration/" + semesterId);
};

const prepStudentsSemesterStatusUpdate = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/prep/students/update-semester-statuses/", formData);
};
const bulkAddressUpdate = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/students/bulk-update-contact-information-from-kps", formData);
};
const foreignStudentCommunityInternshipAssignment = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/course/registration/auto-internship-course-registration", formData);
};

const registrationsBulkDelete = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/registrations/bulk-delete", formData, {responseType: 'arraybuffer'});
};
const courseDropCommand = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/course/registration/not-confirmation-courses-delete");
};
const fileImport = async (file) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/students/excel-import', file);
}
const studentExcelImport = async (file) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/students/class/excel-import', file);
}
const bulkTranslationImport = async (file) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/translations/excel-import', file);
}

const updateSummerSchoolPaidStatus = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/courses/update-summer-school-paid-status', formData);
}

const studentProgramsGraduationRequirement = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/programs/graduation-requirement/excel-import', formData);
}

const deleteEmptySummerStudentSemester = async (semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/semesters/delete-empty-summer-student-semester/" + semesterId);
}

const createSectionForRepeats = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/thesis/and/projects/create-section-for-repeats");
};

const scholarshipsImport = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_FINANCE;
    return Api.post("/scholarships/import", formData);
};

export default {
    getAll,
    getMyLogs,
    get,
    download,
    downloadFailLog,
    calculateExtraGraduateCourses,
    autoCourseRegistration,
    autoRepeatCourseRegistration,
    autoIrregularCourseRegistration,
    createStudentSemesters,
    assignDoesntHavePaymentApproval,
    assignDoesntHaveCourseRegistration,
    prepStudentsSemesterStatusUpdate,
    bulkAddressUpdate,
    foreignStudentCommunityInternshipAssignment,
    registrationsBulkDelete,
    courseDropCommand,
    fileImport,
    studentExcelImport,
    bulkTranslationImport,
    updateSummerSchoolPaidStatus,
    studentProgramsGraduationRequirement,
    deleteEmptySummerStudentSemester,
    createSectionForRepeats,
    scholarshipsImport
};
